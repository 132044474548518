import ApprovalToolbar from '@/components/ApprovalToolbar.vue'
import RequestDetails from '@/components/RequestDetails.vue'

export default {
  name: 'Show',
  components: { RequestDetails, ApprovalToolbar },
  data() {
    return {}
  }
}
